// dependencies
import React, { useState } from 'react';
import { Link, Element } from 'react-scroll';
// components
import PriceTag from '../components/PriceTag';
// assets
import fullMenu from '../assets/data/full_menu.json';
import lunchMenu from '../assets/data/lunch_menu.json';

const Menu = () => {
    const [menuType, setMenuType] = useState('full');
    const [menu, setMenu] = useState(fullMenu);

    const handleMenuChange = (type) => {
        if (type === 'lunch') {
            setMenuType('lunch');
            setMenu(lunchMenu);
        } else {
            setMenuType('full');
            setMenu(fullMenu);
        }
    };

    return (
        <div id='menu' className='content'>
            <div className='menu__control'>
                <div className='menu__control_menus'>
                    <span className={`${menuType === 'full' ? 'menu-selected' : ''}`} onClick={() => handleMenuChange('full')}>Full Menu</span>
                    <span className={`${menuType === 'lunch' ? 'menu-selected' : ''}`} onClick={() => handleMenuChange('lunch')}>Lunch Menu</span>
                </div>
                <div className='menu__control_sections'>
                    {Object.keys(menu).map((title) => (
                        <Link
                            key={menu[title].slug}
                            to={menu[title].slug}
                            smooth={true}
                            duration={500}
                            offset={-50}
                        >
                            <span className='menu__control_section'>
                                {title}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
            {Object.entries(menu).map(([title, section]) => (
                <div className='menu__section' key={section.slug}>
                    <Element name={section.slug} className='menu__section_title'>
                        {title}
                    </Element>
                    {section?.description && (
                        <span className='menu__section_desc'>{section.description}</span>
                    )}
                    <div className='menu__items'>
                        {section.items.map((item) => {
                            return (
                                <div className='menu__item' key={item.name}>
                                    <div className='menu__item_top'>
                                        <span className='menu__item_name'>{item.name}</span>
                                        {item.price && (
                                            <PriceTag price={item.price} />
                                        )}
                                    </div>
                                    <span className='menu__item_desc'>{item.description}</span>
                                </div>
                            );
                        })}
                        <div className='mid-line' />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Menu;
